.section.-banners {
	padding-top: 0;
	padding-bottom: 0;
	background: linear-gradient(
		to bottom,
		transparent 0%,
		transparent 50%,
		$footer-bg-color 50%
	);

	.centered {
		
		box-shadow: 0 10px 50px 0px rgba(0, 0, 0, .2);
		background-color: $banners-bg-color;
		color: $banners-color;
		padding: clamp(24px, 3vw, 48px) clamp(25px, 4vw, 80px);

		@include min(1024px) {
			display: grid;
			grid-template-columns: 1fr 2fr;
		}

		@include link {
			color: inherit;
		}
	}

	.text-component {
		padding-top: 2rem;
		padding-bottom: 2rem;
		margin-bottom: 0;
	}
	.images-container.image-align-center {
		display: flex;
		flex-direction: row-reverse;
		gap: 2rem;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap-reverse;
	}
}

.social {
	display: flex;
	flex-wrap: wrap;
	gap: 60px;
	line-height: 1.1;
	padding: 12px 0;

	h2.element-type-heading {
		margin: 0 0 0.5rem;

		+ p {
			margin: 0;
			line-height: inherit;
		}
	}

	&__list {
		display: inline-flex;
		align-items: center;
		gap: 12px 24px;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	&__item,
	&__link {
		display: inline-flex;
		align-items: center;
	}
	&__item {
		margin: 0;
	}
	&__link {
	}
	&__icon {
		fill: $social-icon-color;
		transition: fill 0.16s ease-out;
	}

	@include link-over(".social__link") {
		.social__icon {
			fill: $social-icon-color-hover;
		}
	}
}
figure.image.images-container.image-align-center a:nth-child(6) {
    width: 10rem !important;
}
figure.image.images-container.image-align-center a:last-child {
    width: 10rem !important;
}
figure.image.images-container.image-align-center a {
    width: 24rem;
}