@use "sass:math";

.board-card {
	min-height: 15rem;
	width: 100%;
	padding: 5%;
	position: relative;
	background-color: transparent;
	transition: box-shadow 0.16s ease-out, background-color 0.16s ease-out;
	box-shadow: 0 0 0 2px #fff, 0 0 122px rgba(#000, 0.14);
	//min-height: 16em;

	&:hover,
	&:focus-within {

		background-color: $news-card-bg-color;
		box-shadow: 0 0 0 2px #fff, 0 30px 84px rgba(#000, 0.34);
		.board-card__title{
			color:#637600 !important;
		}
	}

	@include min(650px) {
		padding: 2em;
	}

	@include min(1024px) {
		padding: 3em 3vw;

		.section.-board.-board-with-picture & {
			padding: 1em 3vw;
		}
	}

	&__top {
		font-weight: bold;
		color: $news-date-color;
	}

	&__date {
		display: block;
		font-family: $font-headings;
		font-size: 1rem;
		font-weight: bold;
		color: $news-date-color;
		margin: 0 0 0;
		&.-to {
			font-size: rem(16px);
			font-weight: 500;
			margin: 0 0 1.25em;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 0.33em;
		font-size: rem(20px);
		color: inherit;
		font-family: $font-headings;
		font-weight: bold;
		line-height: math.div(30, 22);
	}
	&-tags {
		position: relative;
		z-index: 20;
		list-style-type: none;
		margin: 0 0;
		padding-left: 0;
		&:empty {
			display: none;
		}
		&__item {
			display: inline-block;
			vertical-align: top;
			margin: 0 0.35em 0.35em 0;
		}
	}
}

@include link(".board-card__link") {
	color: $news-title-color;
	text-decoration: none;

	&::after {
		content: "";
		@include fill;
	}

	.board-card__title {
		color: inherit;
	}
}
/*
@include link-over(".board-card__link") {
	//color: $news-color-hover;
	text-decoration: none;
} */
.site_board__item {
    display: flex !important;
    align-self: auto !important;
}