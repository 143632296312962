
.section.-news {
	position: relative;
}

.news {
	ul.grid {
		margin-bottom: 0;
	}

	@include min(800px) {
		.grid__item {
			padding-top: 0 !important;
		}
	}
}

.site_board {
	&__list {
		padding-left: 0;
		padding-right: 0;
		margin: 0 0 3em;
		display: grid;
	}
	&__item{
		align-self: flex-start;
	}
	.board-card {
		flex-grow: 1;
	}
}

.section.-board.-board-grid {
	.site_board {
		&__list {
			grid-gap: 30px clamp(25px, 3.5vw, 60px);
			gap: 30px clamp(25px, 3.5vw, 60px);

			@include min(800px) {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
	}
}

.section.-board.-board-with-picture {
	.site_board {
		&__list {
			gap: 20px;
			justify-content: stretch;
			grid-column: 1;
		}
	}

	.dev-element-block {
		@include min(1024px) {
			display: grid;
			grid-template-columns: 1fr 350px;
			gap: 0 clamp(40px, 7.5vw, 155px);
		}

		@include min(1500px) {
			grid-template-columns: 1fr 550px;
		}

		@include min(1024px) {
			.ud-link-additional {
				grid-column: 2;
			}
		}

		.board-more {
			justify-self: end;
		}

		.section-block {
			display: contents;
		}
	}

	.centered {
		.board-additional {
			flex-direction: column;
			justify-content: center;
			display: flex;
			padding-left: 4rem;

			@include min(1024px) {
				padding-left: 0;
			}

			.list {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.board-additional__link,
			.list-item {
				margin: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: relative;
			}

			.board-additional__link {
				font-size: rem(22px);

				@include min(1400px) {
					font-size: rem(33px);
				}

				@include link(&) {
					color: $more-color;
					text-decoration: underline;
				}
				@include link-over(&) {
					text-decoration: none;
				}
			}

			.board-additional__image {
				display: block;
				margin: 0;
				float: none;
				max-width: 100%;

				img {
					aspect-ratio: 1 / 1;
					width: 100%;
					height: auto;
					box-shadow: 0 30px 184px rgba(#000, 0.15);
				}
			}

			.board-additional__title {
				background-color: white;
				position: absolute;
				top: 50%;
				left: -10%;
				transform: translate(0, -50%);
				margin: 0;
				padding: clamp(25px, 3vw, 60px);
				max-width: 200px;
				text-align: center;

				@include min(1600px) {
					max-width: 290px;
				}
			}
		}
	}
}

.main-anchor {
	background: $find-more-bg;
	position: absolute;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	padding: 112px 34px;
	bottom: -40%;
	left: 0;
	z-index: 2;
	display: none;
	@include min(600px){
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		padding: 70px 24px;
	}

	@include min(1730px) {
		top: auto;
		bottom: -40%;
		padding: 112px 34px;
	}

	&::after {
		content: "";
		position: absolute;
		right: -7px;
		//bottom: 100%;
		top: -31px;
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 32px 32px 0 0;
		border-color: $find-more-bg transparent transparent transparent;
		transform: rotate(180deg);
	}

	@include link(&) {
		color: #36393c;
		text-transform: uppercase;
		font-size: rem(15px);
		font-weight: 900;
		letter-spacing: 0.2em;
		text-decoration: none;
	}
}

.news-perex,
.board-perex,
.events-perex,
.gallery-perex {
	max-width: rem(430px);
	font-size: rem(15px);
}

.section-block {
	display: grid;
	@include min(800px) {
		grid-template-columns: 1fr max-content;

		[class*="-perex"],
		[class*="-more"] {
			align-self: end;

			.text-component {
				margin: 0;
			}
		}
	}

	[class*="-more"] .text-component {
		overflow: visible;
	}

	@include min(800px) {
		.element-type-heading,
		.list,
		.vismo-editable {
			grid-column: 1 / -1;
		}
	}

	.list {
		margin-top: clamp(30px, 3vw, 62px);
	}
}
section.section.-board.-board-grid{
	padding: 0 !important;
}