
.tag{
	text-decoration: none;
	display: inline-block;
	padding: .25rem .5rem;
	border-radius: 3px;
	text-align: center;
	font-weight: 700;
	font-size: rem(14px);
}

@include link(".tag") {
	color: $tags-color;
	text-decoration: none;
	background: {
		color: $tags-bg-color;
		repeat: repeat-x;
		size: auto 200%;
		position: 0 0;
	}
	.board-card &{
		background-color: $board-tags-bg-color;
		color: $board-tags-color;
	}
	&.-isLocked{
		background-color: #e1e1e1!important;
		color: #999!important;
	}
}

@include link-over(".tag") {
	background-color: $tags-bg-color-hover;
	color: $tags-color-hover;
	.board-card &{
		background-color: $board-tags-bg-color-hover;
		color: $board-tags-color-hover;
	}
}
