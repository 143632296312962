@use "sass:math";

.card {
	background-color: $news-card-bg-color;
	color: $news-color;
	position: relative;
	padding-bottom: 2.5rem;

	&__image {
		display: flex;
		flex-direction: column;
	}

	&__image:not(:empty) {
		position: relative;
		margin: 0;
		overflow: hidden;

		img,
		svg {
			margin: 0;
			width: 100%;
			object-fit: cover;
			object-position: center;
			aspect-ratio: 430 / 280;
			transform: scale(1);
			transition: transform 0.3s ease-in-out;
		}
	}

	&__image:empty {
		aspect-ratio: 430 / 280;
		background-color: $news-date-color;
	}

	&__date {
		color: $news-date-color;
		margin: 0 12%;
		padding-top: 8%;
		padding-bottom: 0.5rem;
		font-size: rem(15px);
		font-weight: bold;
		display: block;
	}

	&__link {
		&.-cover-link {
			&::after {
				@include fill;
				content: "";
			}
		}

		&:hover,
		&:focus {
			color: inherit;
		}
	}

	&__title {
		margin: 0 12%;
		padding-bottom: 6%;
		font-size: rem(20px);
		font-weight: 700;
		color: inherit;
	}

	.info-hidden,
	.info-concept {
		display: inline-flex;

		&::before {
			top: 1px;
		}
	}

	&__description {
		font-size: rem(17px);
		line-height: math.div(28, 17);
		margin: -2% 12% 0;
		padding-bottom: 6%;
	}

	&__folder{
		position: relative;
		z-index: 20;
		margin: 0 12% 0;
		padding-bottom: 6%;
		@include link(){
			color: $news-folder-color;
		}
	}

	&-tags {
		position: relative;
		z-index: 20;
		list-style-type: none;
		padding-left: 0;
		margin: 0 12%;
		&:empty{
			display: none;
		}
		&__item{
			display: inline-block;
			vertical-align: top;
			margin: 0 .35em .35em 0;
		}
	}
	@include link-over(&) {
		color: $news-color-hover;
		.card__image img {
			transform: scale(1.1);
		}
		.card__date {
			color: $news-date-color-hover;
		}
		.card__folder{
			@include link(){
				color: $news-folder-color-hover;
			}
		}
	}
}

@include link(".card__link") {
	color: $news-title-color;
	text-decoration: none;
}

@include link-over(".card__link") {
	color: $news-title-color-hover;
	text-decoration: underline;
}
