.section.-abo {
	padding: 0;
	background: linear-gradient(
		to bottom,
		$events-bg-color 0%,
		$events-bg-color 50%,
		transparent 50%
	);

	h2.element-type-heading {
		text-align: center;
		color: $abo-heading-color;
	}

	.centered {
		//max-width: 780px;
		background: $abo-background-color
			linear-gradient(
				290deg,
				$abo-background-color-secondary,
				$abo-background-color
			);
		color: white;
		padding: clamp(25px, 2vw, 75px) clamp(30px, 5vw, 100px);
	}
}

.abo {
	text-align: center;
	color: $abo-color;

	&__form {
		.form__section {
			align-items: center !important;
		}

		@include link {
			color: inherit;
			font-weight: bold;
		}

		.label {
			position: absolute !important;
			left: -9999rem !important;
			top: auto;
			outline: 0;
			width: 0;
			height: 0;
			overflow: hidden;
		}

		.input {
			background-color: $abo-input-bg-color;
			border-color: $abo-input-bg-color;
			color: $abo-input-color;
			height: 53px;
			text-align: center;
			box-shadow: 0 0 0;
			border-radius: 6px;

			&#sub-email {
				max-width: 600px;
			}
		}

		.gdpr-agreement {
			margin: 0;
		}

		.form__button {
			color: $abo-submit-color;
			background-color: $abo-submit-bg-color;
			padding: 1em 2em;
			height: 53px;
			font-weight: bold;
			display: inline-block;
			min-width: 208px;
			cursor: pointer;
			border-radius: 6px;
			line-height: 1.53;
			border: 2px $abo-submit-border-color solid;
			font-weight: bold;
			font-size: rem(14px);

			&.-primary:not([disabled]) {
				color: $abo-submit-color;
				background-color: $abo-submit-bg-color;

				@include link-over(&) {
					box-shadow: 0 0 10px #2a3133;
				}
			}
		}
	}
}
