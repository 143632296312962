.additional {
	color: $additional-section-color;
	margin: 100px 0;

	@include link("a:not(.more__button)") {
		color: $additional-section-color;
		text-decoration: underline;
	}

	@include link-over("a:not(.more__button)") {
		text-decoration: none;
		color: $additional-section-color-hover;
		font-weight: 700;
	}

	&__content {
		display: grid;
		grid-gap: clamp(25px, 2.5vw, 50px);
		gap: clamp(25px, 2.5vw, 50px);
	}

	&.additional-compact {
		.additional__content {
			@include min(900px) {
				grid-template-columns: 1fr 1fr 1fr;
			}

			@include min(1200px) {
				grid-template-columns: 1fr 1fr 25%;
			}
		}
		.additional__section.hours,
		.additional__section.contact {
			background-image: encode-svg(
				'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 342.7 410.7" xml:space="preserve"><style>.st1{fill:#{$additional-section-ico-color}</style><defs><path id="SVGID_1_" d="M0 0h342.7v410.7H0z"/></defs><clipPath id="SVGID_00000023266347227188563880000000153415978056101257_"><use xlink:href="#SVGID_1_" overflow="visible"/></clipPath><g transform="matrix(1.33333 0 0 -1.33333 0 410.667)" clip-path="url(#SVGID_00000023266347227188563880000000153415978056101257_)"><path class="st1" d="M204.3 22.8C135.8 24.8 75 83.4 78 159.8c2.6 66.2 59.7 126.3 131.2 124.8 16.5.4 32.8-2.7 47.9-8.8v11.6c-13.5 5-27.6 7.9-41.9 8.4-84.2 3.1-145.6-65-148.3-136-3.2-84.2 67-149.8 142.2-147.7 16.9-.3 33 2.6 47.9 8V32c-15.9-6.4-33.7-9.8-52.7-9.2m13.7 128c-3.2-3.7-8.5-4-12-.8-3.3 3-3.6 8.6-.7 11.9 3 3.4 8.7 3.8 12.2.6 3.3-3 3.6-8.2.5-11.7m-14.2 23.3c-2.3-1-3.8-.5-5.5 1.1L182.7 189l-16.4 14.5c-3.7 3.1-8.6 2-9-2.3-.2-2 1.2-4.7 2.8-6.1 10-9.2 20.3-18.2 30.6-27.1 2.3-2 2.8-3.8 2.3-6.8-.6-3.8-.3-8 .7-11.7.8-2.9.7-4.7-1.4-6.8-1.9-1.9-3.6-4-5.3-6l-13.5-15.4c-2-2.4-1.8-5.6.2-7.6s5.2-2 7.6 0c.9.8 1.7 1.7 2.5 2.6l16.9 19.2c1.8 2.1 3.4 2.8 6.3 2 9-2.4 19.4 2.5 22.5 10.6 3.3 8.5 2.2 16.4-4.7 22.7-6.1 5.4-13.3 6.7-21 3.3m1.3 88.2c-2.6-2.6-2.3-9.9.4-12.5 2.4-2.2 5.3-2.1 7.4.1 2.1 2.2 2 10.3-.1 12.4-1.9 1.8-5.9 1.8-7.7 0m-69.6-28.6c-2.6-.1-5.5-2.9-5.4-5.5 0-3.7 5.4-8.7 9.1-8.5 3.2.1 5.2 2.2 5.2 5.3-.1 3.1-5.9 8.8-8.9 8.7M101 158.9c-1.8-1.9-1.8-5.9 0-7.7 2.6-2.6 9.9-2.3 12.5.4 2.2 2.4 2.1 5.3-.1 7.4-2.2 2.1-10.3 2-12.4-.1"/><path class="st1" d="M138.2 90.4c-3-.1-8.7-5.9-8.6-8.8.1-2.6 2.9-5.5 5.5-5.4 3.7 0 8.7 5.4 8.5 9.1-.2 3.1-2.3 5.2-5.4 5.1m66.1-31c-2.1-2.2-2-10.3.1-12.4 1.9-1.8 5.9-1.8 7.7 0 2.6 2.6 2.3 9.9-.4 12.5-2.3 2.2-5.2 2.2-7.4-.1"/></g></svg>'
			);
			background-position: 100% 50%;
			background-repeat: no-repeat;
			background-size: 60%;
		}
		.additional__section.contact {
			background-image: encode-svg(
				'<svg version="1.1" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 342.7 410.7" xml:space="preserve"><style/><defs><path id="SVGID_1_" d="M0 0h342.7v410.7H0z"/></defs><clipPath id="SVGID_00000138542929026161095630000009337601445655272363_"><use xlink:href="#SVGID_1_" overflow="visible"/></clipPath><g transform="matrix(1.33333 0 0 -1.33333 0 410.667)" clip-path="url(#SVGID_00000138542929026161095630000009337601445655272363_)"><path d="M213.8 49.1c-22.1 10.3-41.6 24.7-59.7 40.9-23.1 20.6-44.6 42.8-62.5 68.2-13.1 18.6-24 38.3-28.5 61-2.1 10.7-2.3 21.5.2 32.2 1.5 6.5 4.8 11.6 10.5 15.1l21.3 12.8c4 2.4 7.2 1.7 9.8-2.1l34-51c2.8-4.2 2.2-7.3-1.8-10.5l-17-13.2c-8.4-6.5-9.7-13.9-4.6-23.4 12.5-23.6 30.9-42.3 50.1-60.3 11.1-10.3 23-19.4 36.4-26.7 10.6-5.8 17.8-4.6 25.1 4.9l12.3 15.9c3.4 4.3 6.5 4.9 11.1 1.9l6.7-4.4v11.7l-1.5 1c-8.5 5.6-16.5 4.6-22.9-3.2-4.8-5.8-9.3-11.9-14-17.8-3.1-3.9-6-4.7-10.2-2.4-5.6 3.1-11.3 6.3-16.5 10-17.1 11.9-31.5 26.8-45.4 42-9 9.8-16.7 20.6-22.8 32.5-2.7 5.2-2.2 7.3 2.5 10.9 6.1 4.8 12.4 9.4 18.3 14.5 6.8 5.9 7.8 13.7 2.8 21.3l-34.4 51.6c-5.8 8.5-13.9 10.3-22.8 5.1-7.1-4.1-14-8.4-21-12.6-8.4-5-13.4-12.5-15.5-22-2.9-13.6-2.4-27.1 1-40.5 5.7-23.1 17.1-43.3 30.9-62.4 15.9-22.1 34.4-41.9 54.4-60.3 15.3-14.2 31.4-27.3 49.2-38.2 6-3.7 12.2-7 18.6-10.6-1.2-.3-2.1-.7-3-.8-14.5-2.5-29.1-2.5-43.6.1C105.1 50.5 64 99 62.7 156.2c0 1.3.2 3-.5 3.9-1.2 1.6-2.9 3.5-4.7 3.7-2.5.4-4.1-1.7-4.5-4.3-.1-1.1-.1-2.2-.1-3.3 1.4-41.2 18.2-74.9 50.6-100C138 29.6 176.9 22.3 219 33.7c3.4.9 6.4.4 9.6-.6 9.3-3 18.8-4.7 28.4-5v9.3c-15.3.9-29.5 5.3-43.2 11.7m21.1 230.3c-15.7 7.1-32.3 10.5-49.6 10.4-1.9 0-4.3-.7-5.5-2-2.6-2.7-.5-6.8 3.6-7.3 4.6-.6 9.2-.6 13.8-1.1 22.8-2.7 42.8-10.5 59.7-23.6v11.5c-6.7 4.5-14 8.5-22 12.1" fill="#{$additional-section-ico-color}"/></g></svg>'
			);
		}
	}
	&.additional-full {
		.additional__content {
			@include min(800px) {
				grid-template-columns: 1fr 1fr;
			}
			@include min(1200px) {
				grid-template-columns: 1fr 25%;
			}
		}
	}

	&__section {
		background-color: $additional-section-bg-color;
		color: $additional-section-color;
		padding: clamp(20px, 2vw, 60px) clamp(25px, 3vw, 70px);
		box-shadow: 0 0 122px rgba(#152d57, 0.15);

		//&.additional-links {
		//	max-width: 330px;
		//	//margin-left: clamp(25px, 2.5vw, 50px);
		//}
	}

	&__group {
		padding: clamp(20px, 3vw, 60px) clamp(25px, 4vw, 70px);
		display: grid;
		grid-template-columns: 220px 1fr 1fr;
		grid-gap: clamp(25px, 2.5vw, 50px);
		gap: clamp(25px, 2.5vw, 50px);

		.additional__section {
			box-shadow: 0 0 122px rgba(#152d57, 0.15);

			@include min(1200px) {
				box-shadow: none;
				padding: 0;
			}
		}
	}

	&.additional-compact {
		.additional__section {
			box-shadow: 0 0 122px rgba(#152d57, 0.15);
		}

		/* .additional__section:not(:first-child) {
			margin-left: clamp(25px, 2.5vw, 50px);
		} */
	}
	&.additional-full {
		.additional__group {
			background-color: $additional-section-bg-color;
			box-shadow: 0 0 122px rgba(#152d57, 0.15);

			@include minmax(1px, 1200px) {
				display: contents;
			}
		}

		//.additional__section.hours {
		//	padding-left: 0;
		//	padding-right: 0;
		//}
	}

	&__heading,
	.element-type-heading {
		font-size: rem(33px);
		color: $additional-section-color;
		font-weight: bold;
		margin-bottom: 1.25em;
	}
	.more{
		text-align: left;
	}
	.text-component{
		overflow: visible;
	}
}
