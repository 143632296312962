// Colors

$header-bg-color: #ffffff;
$header-color: #444444;

$button-radius: 4px;
$button-bg: #edeff4;
$button-fg: #36393c;
$button-primary-bg: #addb67;
$button-primary-fg: #fff;

$body-gradient-bg-color: #fefefe;
$body-gradient-bg-accent1: #d5cfe5;
$body-gradient-bg-accent2: #c9dcf4;

$header-home-heading-color: #333333;
$header-home-subheading-color: #666666;

$main-nav-upper-link-color: #222526;
$main-nav-color: $header-color;
$main-nav-color-hover: #637600;
$main-nav-button-bg-hover: #addb67;

$sidebar-nav-color: #474848;
$sidebar-nav-color-active: #637600;
$sidebar-border-color: #edeff4;

$search-bg: #d9d9d9;
$search-color: #0f0f0f;
$search-button-color: #0f0f0f;

$gte-bg-color: $search-bg;
$gte-ico-color: $search-color;

$color-h2: #addb67;

$find-more-bg: #addb67;

$color-anchors: #36393c;

$tile-bg-color: #edeff4;
$tile-color: #36393c;
$tile-bg-color-hover: #addb67;
$tile-color-hover: #36393c;

$more-border-color: #addb67;
$more-color: #637600;
$more-color-hover: #36393c;

$tags-bg-color: #637600;
$tags-color: #ffffff;
$tags-bg-color-hover: #e9e6ed;
$tags-color-hover: #637600;

$news-card-bg-color: #fff;
$news-heading-color: #383433;
$news-title-color: #2a3133;
$news-date-color: #8799a3;
$news-folder-color: #637600;
$news-color: #464646;
$news-title-color-hover: #637600;
$news-date-color-hover: #8799a3;
$news-folder-color-hover: #637600;
$news-color-hover: #464646;

$board-tags-bg-color: #637600;
$board-tags-color: #ffffff;
$board-tags-bg-color-hover: #e9e6ed;
$board-tags-color-hover: #637600;

$abo-background-color: #addb67;
$abo-background-color-secondary: #addb67;
$abo-color: #2a3133;
$abo-heading-color: #2a3133;
$abo-input-bg-color: #e6e6e6;
$abo-input-color: #000;
$abo-submit-bg-color: transparent;
$abo-submit-color: #2a3133;
$abo-submit-border-color: rgba(#2a3133, 0.59);

$events-bg-color: #fff;
$event-bg-color: #e7eeeb;
$event-date-border-color: #c1e48b;
$events-date-color: #637600;
$events-month-color: #2a3133;
$events-title-color: #222526;
$events-title-color-hover: #637600;
$events-type-color: #637600;
$events-color: #ffffff;
$events-flair-color: rgba(#000, 0.48);

$breadcrumbs-bg-color: #ffffff;
$breadcrumbs-color: #383433;
$breadcrumbs-color-active: $main-nav-color-hover;

$additional-section-bg-color: #fff;
$additional-section-color: #222526;
$additional-section-color-hover: #637600;
$additional-section-ico-color: #f0f0f0;

$contact-ico-color: #ffffff;

$contact-form-input-bg-color: #fff;
$contact-form-input-border-color: #d2d2d3;
$contact-form-input-color: #222526;

$social-icon-color: #242d3a;
$social-icon-color-hover: #637600;

$banners-bg-color: #fff;
$banners-color: #242d3c;

$footer-bg-color: #fcfcfc;
$footer-color: #242d3c;
